@font-face {
  font-family: "IBM Plex Mono Regular";
  src: local("IBMPlexMono"),
  url("https://fonts.gstatic.com/s/ibmplexmono/v19/-F63fjptAgt5VM-kVkqdyU8n5is.woff") format("woff");
  font-weight: normal;
}

@font-face {
  font-family: "IBM Plex Sans Regular";
  src: local("IBMPlexSans"),
  url("https://fonts.gstatic.com/s/ibmplexsans/v19/zYXgKVElMYYaJe8bpLHnCwDKtdU.woff") format("woff");
  font-weight: normal;
}

@font-face {
  font-family: "IBM Plex Sans Medium";
  src: local("IBMPlexSans"),
  url("https://fonts.gstatic.com/s/ibmplexsans/v19/zYX9KVElMYYaJe8bpLHnCwDKjSL9MIU.woff") format("woff");
  font-weight: normal;
}

@font-face {
  font-family: "IBM Plex Serif Regular";
  src: local("IBMPlexSerif"),
  url("https://fonts.gstatic.com/s/ibmplexserif/v19/jizDREVNn1dOx-zrZ2X3pZvkThUb.woff") format("woff");
  font-weight: normal;
}

@font-face {
  font-family: "IBM Plex Serif Medium";
  src: local("IBMPlexSerif"),
  url("https://fonts.gstatic.com/s/ibmplexserif/v19/jizAREVNn1dOx-zrZ2X3pZvkTi3s-BIw.woff") format("woff");
  font-weight: bold;
}

// override bootstrap variables
$font-family-base: "IBM Plex Sans Regular", sans-serif;

@import "./_bee_variables.scss";
// end override bootstrap variables

@import "../../node_modules/bootstrap/scss/bootstrap";
@import '../../node_modules/react-bootstrap-typeahead/css/Typeahead';

//TODO: remove as many overrides as possible with variable declarations above the import

@each $color, $value in $grays {
  .bg-gray-#{$color} {
    background-color: $value;
  }
}

@each $color, $value in $grays {
  .border-gray-#{$color} {
    border: 1px $value solid;
    border-radius: $border-radius;
  }

  .box-shadow-gray-#{$color} {
    box-shadow: 0 0 1px 2px $value !important;
  }
}

@mixin box-shadow-variant($color) {
  background: $white !important;
  box-shadow: 0 0 1px 2px $color !important;

  .card-body .card-body {
    background: darken($white, 2%) !important;
  }
}

@mixin toast-variant($color) {
  @include box-shadow-variant($color);
  border-color: $gray-400;
  min-width: 200px;
  top: 100px;
  right: 10px;

  a {
    color: darken($color, 10%);
  }

  .close {
    margin-top: -3px;
    margin-right: -2px;
  }
}

.toast-container {
  position: fixed;
  z-index: 999999;
}

.top-right {
  top: 12px;
  right: 12px;
}

@each $color, $value in $theme-colors {
  .toast-#{$color} {
    @include toast-variant($value);
  }

  .box-shadow-#{$color} {
    @include box-shadow-variant($value);
  }
}

.toast-error {
  @extend .toast-danger; // Support django having "error" messages rather than "danger"
}

:root {
  --input-focused-bg-color: #{$black};
  --input-unfocused-bg-color: #{$gray-500};
  --ck-z-default: 100 !important;
  --ck-z-panel: calc(var(--ck-z-default) + 999) !important;
  // TODO how to override ck editor colors?
  //--ck-border-radius: $border-radius;
  //--ck-color-base-active: $body-color;
  //--ck-color-focus-border: $border-color;
}

.navbar-dark .nav-tabs {
  margin-bottom: -1px;

  .nav-link {
    background-color: $gray-700;
    border: $nav-tabs-border-width solid $gray-800;
    border-top-width: $nav-tabs-border-width * 2;
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    color: $white;
    background-color: $gray-800;
  }
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $placeholder-color !important;
  font-size: 14px !important;
  opacity: 1; /* Firefox */
}

:hover::placeholder, :focus::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $placeholder-color-focus !important;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $placeholder-color !important;
}

:hover:-ms-input-placeholder, :focus:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $placeholder-color-focus !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: $placeholder-color !important;
}

:hover::-ms-input-placeholder, :focus::-ms-input-placeholder { /* Microsoft Edge */
  color: $placeholder-color-focus !important;
}

.top-minus-1 {
  margin-top: -1px;
}

.top-minus-3 {
  margin-top: -3px;
}

.input-hover {
  color: $body-color;
  background-color: $select-bg-hover;
  border-color: $body-color;
}

.list-group-item-primary {
  color: $body-color;
  background-color: $input-focus-bg;
  border: 1px solid $body-color;
  padding-right: 8px !important;
}

.smaller {
  font-size: 0.875rem;
}

.underline-link {
  text-decoration: underline;
  text-decoration-thickness: 1.5px;
}

.link-emphasis {
  @extend .underline-link;
  color: $gray-700;
}

a {
  text-underline-offset: 4px;
  text-decoration-thickness: 1px;

  &:hover {
    color: $body-color;
    text-decoration-thickness: 1px;
  }
}

.card-header span {
  font-weight: 500;
}

.logo a {
  font-family: "IBM Plex Serif Regular", "serif";
  font-weight: 500;
  font-size: 24px;
  color: $dark !important;

  &:hover {
    @extend .underline-link;
  }
}

.ingestion-title {
  @extend .text-secondary;
  font-size: 28px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0;
  text-align: left;
}

.form-additional-text {
  font-size: 14px;
}

.form-group {
  input[type=text], input[type=email], input[type=url], select {
    box-sizing: border-box;
    height: 40px;
  }

  div[role='button']:hover {
    @extend .input-hover;
  }
}

.bee-file-upload .form-group {
  div[role='button']:hover {
    color: $secondary !important;
    background-color: $input-focus-bg;
    border-color: $black !important;
  }
}

select:hover {
  background-color: $input-focus-bg;
}

.form-label {
  margin-bottom: 4px;
}

.form-group {
  input[type=radio] {
    appearance: none;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px $body-color solid;
  }

  input[type=radio]:checked {
    border: 1px $body-color solid;
    background-color: $body-color;
  }
}

.rbt-input-wrapper {
  @extend .smaller;
}

.rbt-menu {
  background-color: $input-focus-bg;
}

.rbt-menu a:hover,
.rbt-menu a:focus,
.rbt-menu a.active {
  color: $body-color;
  background-color: $select-bg-hover;
}

.tag-input .rbt-input-main {
  height: 27px !important;
  margin-bottom: 3px;
}

.rbt-input-multi, .rbt-input-main {
  &.focus {
    color: $body-color;
    border: 1px $border-color solid;
    box-shadow: none;
  }
}

.rbt-token {
  color: $body-color;
  background-color: $input-focus-bg;
  border: 1px $border-color solid;
}

.rbt-close-content {
  @extend .top-minus-1;
  font-size: 14px;
  background-color: $primary !important;
  padding: 2px 4px;
  border-radius: 3px;
}

.my-react-select__control {
  background-color: $input-bg !important;

  &:hover, &--is-focused {
    background-color: $input-focus-bg !important;
    border-color: $border-color-focus !important;
    box-shadow: none !important;
  }
}

.my-react-select__input-container, .my-react-select__value-container {
  margin: 0 4px !important;
  padding: 0 4px !important;
}

.my-react-select__menu {
  margin-top: 0 !important;
  background-color: $input-focus-bg !important;
}

.my-react-select__option {
  color: $body-color !important;
  background-color: $input-focus-bg !important;

  &:hover, &--is-focused {
    color: $body-color !important;
    background-color: $select-bg-hover !important;
  }
}

.my-react-select__placeholder {
  font-size: 14px;
}

.form-control {
  &:hover, &:focus {
    color: $body-color;
    background-color: $input-focus-bg;
    border-color: $border-color-focus;
    box-shadow: none;
  }
}

.center-horizontal {
  display: flex;
  justify-content: center;
}

.btn {
  &:hover, &.hover,
  &:active, &.active,
  &:visited, &.visited,
  &:focus, &.focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    box-shadow: none !important;
  }
}

.text-gray-800 {
  color: $gray-800;
}

.btn-primary {
  @include button-variant($primary, $primary, $hover-background: lighten($primary, 10%), $hover-border: lighten($primary, 5%), $active-background: lighten($primary, 10%), $active-border: lighten($primary, 10%));
}

.btn-tertiary {
  @include button-variant($gray-500, $gray-500);
}

.btn-outline-secondary {
  @include button-outline-variant($secondary, $body-color, $input-focus-bg, $border-color-dark);
}

.btn-outline-light-emphasis {
  @include button-outline-variant($gray-700, $body-color, darken($gray-200, 8%), $gray-500);
  border-color: $gray-400;
  background-color: darken($gray-200, 4%);
}

.btn-outline-heavy-emphasis {
  @include button-outline-variant($gray-800, $body-color, lighten($gray-300, 6%), $gray-500);
  border-color: $gray-500;
  background-color: $gray-300;
}

.badge-outline {
  color: $secondary;
  border: $border-color-dark 1px solid;
  border-radius: $border-radius;
}

.ingestion-body-long {
  font-size: 14px;
}

.ingestion-btn {
  height: 64px;
  margin-left: 28px;
  border-radius: 8px;
}

.ingestion-btn div {
  font-weight: 600;
  font-size: 20px;
  line-height: 18px;
  padding: 18px 60px;
}

.ingestion-form {
  padding: 0 20px;

  @media (max-width: 1023px) {
    padding: 0 10px;
  }
}

.ingestion-form .col-md-6 {
  padding-left: 24px;
}

.ingestion-form label {
  font-size: 14px;
}

.max-width-medium {
  width: 100%;
  max-width: 400px;
}

.overlay-parent {
  position: relative;
  height: 48px !important;
}

.overlay-button {
  position: absolute;
  top: 30px;
  right: 0;
}

table.dataTable > tbody > tr.selected form {
  color: $gray-700;
}

.datatables-button-spacing .dt-override-btn {
  @extend .btn-outline-secondary;
  background-color: $body-bg;
}

.datatables-button-spacing .dataTables_filter {
  margin-top: 3px;
  padding-left: 20px;
  display: inline-block;
}

.bee-slides {
  h1 {
    font-size: 54px;
    text-align: center;
  }

  h2 {
    font-size: 48px;
  }
}

#indicator {
  top: auto;
  bottom: 0.4em;
  right: 0.8em;
  font-size: 24px;

  &:is(.has-items *)::before {
    content: "";
    position: absolute;
    top: 0.2em;
    left: auto;
    right: -0.6em;
  }
}

$section-margin: 14px;
$presentation-margin: 10px;

.agm {
  a {
    text-decoration: underline;
  }

  .social-button {
    padding-left: 2px;
    padding-right: 2px;
    text-decoration: none;
  }
  
  .speaker:not(.last) {
    margin-bottom: $presentation-margin;
  }
  
  .section {
    margin-bottom: $section-margin;
  }

  .section[aria-expanded="true"] {
    @extend .box-shadow-gray-400;
    margin-bottom: $presentation-margin;
  }
    
  .presentation-card {
    margin-bottom: $presentation-margin;
  }
    
  .presentation-card:last-child {
    margin-bottom: $section-margin;
  }
}

.social-action .social-button {
  @extend .btn-outline-secondary;
  width: 300px;
}

.navbar-bee {
  border-bottom: 1px solid $border-color;
}

.navbar-bee {
  .logo {
    a {
      line-height: 24px;
      font-size: 22px;

      img {
        max-height: 24px;
      }
    }
  }
}

.navbar-bee .nav-link {
  &.active, &:hover {
    color: $dark !important;
  }
}

.navbar-bee .nav-link.active,
.navbar-bee .nav-link:hover, {
  @extend .underline-link;
  color: $gray-800 !important;
}

.navbar-bee .dropdown-menu {
  background-color: $body-bg;
}

.navbar-bee .dropdown-item:hover, .navbar-bee .dropdown-item:focus {
  color: $dark;
  background-color: $gray-200;
}

.navbar-bee .dropdown-item.active {
  background-color: $select-bg-hover;
}

.dataTables_wrapper table.dataTable.table-striped > tbody > tr.odd.selected > * {
  box-shadow: inset 0 0 0 9999px rgba(2, 117, 216, 0.45);
}

.dataTables_wrapper table.dataTable.table-striped > tbody > tr.even.selected > * {
  box-shadow: inset 0 0 0 9999px rgba(2, 117, 216, 0.35);
}

/* Override styles so select2 looks good with bootstrap, as the theme doesn't do quite enough */
.select2-container--bootstrap4 {
  span, textarea {
    background-color: $body-bg;
    border-color: $gray-400 !important;
  }
}

.select2-container .select2-search--inline .select2-search__field {
  height: 27px;
  background-color: transparent;
}

.select2-search--dropdown .select2-search__field {
  background-color: transparent;
}

.select2-container--bootstrap4.select2-container--focus .select2-selection {
  box-shadow: none;
}

.select2-container--bootstrap4 .select2-selection {
  background-color: $input-bg;
}

.select2-container--bootstrap4 .select2-results__option--highlighted, .select2-container--bootstrap4 .select2-results__option--highlighted.select2-results__option[aria-selected="true"] {
  color: $dark !important;
  background-color: $gray-300 !important;
}

.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice__remove {
  padding-top: 0;
  padding-bottom: 0;
}

.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice__remove {
  background-color: transparent !important;
  border: none;
  border-right: 1px solid $gray-400;
  padding: 1px 4px 1px 4px !important;
  margin: 0 !important;
}

.select2-container--bootstrap4 .select2-selection--multiple {
  overflow: hidden !important;
  height: auto !important;
}

.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
}

.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice__display {
  cursor: default;
  padding-left: 4px;
}

.ck {
  background-color: $body-bg !important;
}

.tooltip.show {
  opacity: 1;
}

.tooltip-inner {
  color: $dark;
  background-color: $white;
  border: 1px $border-color solid;
  font-size: 16px;
  max-width: 300px;
}

.thumbnail {
  max-height: 60px;
}
