$white: #ffffff;
$gray-100: #f2f2f2; // from website
$gray-150: #eeeeee; // created this just to have a card background that was slightly offset from body
$gray-200: #e9e9e9; // from website
$gray-300: #cecece;
$gray-400: #b4b4b4;
$gray-500: #8f8f8f; // bee Partners logo gray
$gray-600: #7c7c7c; // from website
$gray-700: #5e5e5e;
$gray-800: #404040;
$gray-900: #222222; // from website
$black: #000000;

$red: #a91c47;
$orange: #f28c28;
$yellow: #ebba1d; // Bee Partners branded yellow
// lightened 10% for hover:
//   background-color: #efc84c;
//   border-color: #edc134;
// Website (semplice) custom css:
//#content .ce-button .is-content, #content-holder .ce-button .is-content {
//    background-color: #EBBA1D !important;
//    border-color: #EBBA1D !important;
//}
//#content .ce-button .is-content:hover, #content-holder .ce-button .is-content:hover {
//    background-color: #efc84c !important;
//    border-color: #edc134 !important;
//}
$green: #2b6c4d;
$cyan: #386b86;
$light: $gray-100;
$primary: $yellow;
$secondary: $gray-700;
$success: $green;
$danger: $red;
$warning: $orange;
$info: $cyan;
$dark: $gray-900;

$border-radius: .25rem;

$link-color: $secondary;
$table-color: $secondary;

$btn-active-box-shadow: none;
$btn-focus-box-shadow: none;

$body-color: $dark;
$body-bg: $light;
$modal-content-bg: $light;
$select-bg-hover: $gray-300;

$border-color: $gray-300;
$border-color-dark: $secondary;
$border-color-focus: $dark;

$nav-tabs-border-radius: 8px;
$nav-tabs-border-color: $gray-500;
$nav-tabs-link-hover-border-color: $gray-400 $gray-400 $nav-tabs-border-color !default;
$nav-tabs-link-active-border-color: $nav-tabs-border-color $nav-tabs-border-color $gray-100 !default;

$navbar-dark-color: $gray-200;
$navbar-dark-hover-color: $gray-100;

$input-bg: $light;
$input-focus-bg: $gray-200;
$input-focus-border-color: $border-color-dark;

$card-color: $dark;
$card-bg: $gray-150;

$component-active-color: $dark !default;
$component-active-bg: $input-focus-bg !default;

$pagination-bg: $body-bg;
$pagination-disabled-bg: $body-bg !default;
$pagination-active-bg: $gray-300 !default;

$placeholder-color: $gray-500;
$placeholder-color-focus: $secondary;

$custom-control-indicator-checked-color: $body-color;
$custom-control-indicator-bg: $input-bg;

$custom-control-indicator-checked-bg: $input-focus-bg;
$custom-control-indicator-checked-border-color: $border-color-dark;
$custom-control-indicator-active-color: $dark;
$custom-control-indicator-active-bg: $input-focus-bg;
$custom-control-indicator-active-border-color: $border-color-dark;
$custom-checkbox-indicator-indeterminate-border-color: $border-color-dark;
$custom-checkbox-indicator-indeterminate-bg: $input-focus-bg;
$enable-validation-icons: false;

$yiq-contrasted-threshold: 174;

$grid-breakpoints: (
        xs: 0,
        sm: 540px,
        md: 768px,
        lg: 992px,
        xl: 1200px
) !default;
